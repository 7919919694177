import { login, userInfo, getOtherSet, getNotice, companyLogin } from "@/api/index";
import { validateMobile } from "@/libs/validate";
import { router } from "@/router";
import Cookies from "js-cookie";
import Header from "@/views/main-components/header";
import Footer from "@/views/main-components/footer";
import LangSwitch from "@/views/main-components/lang-switch";
import RectLoading from "@/views/my-components/xboot/rect-loading";
import CountDownButton from "@/views/my-components/xboot/count-down-button";
import util from "@/libs/util.js";
export default {
  components: {
    CountDownButton,
    RectLoading,
    LangSwitch,
    Header,
    Footer
  },
  data() {
    return {
      showMore: false,
      captchaImg: "",
      loadingCaptcha: true,
      socialLogining: true,
      error: false,
      activeName: 'systemLogin',
      loading: false,
      checkSms: true,
      form: {
        username: "",
        password: "",
        saveLogin: false
      }
    };
  },
  methods: {
    afterLogin(res) {
      let accessToken = res.result;
      this.setStore("accessToken", accessToken);
      getOtherSet().then(res => {
        if (res.result) {
          let domain = res.result.ssoDomain;
          Cookies.set("accessToken", accessToken, {
            domain: domain,
            expires: 7
          });
        }
      });
      // 获取用户信息
      userInfo().then(res => {
        if (res.success) {
          // 避免超过大小限制
          delete res.result.permissions;
          let roles = [];
          res.result.roles.forEach(e => {
            roles.push(e.name);
          });
          delete res.result.roles;
          this.setStore("roles", roles);
          this.setStore("saveLogin", this.form.saveLogin);
          if (this.form.saveLogin) {
            // 保存7天
            Cookies.set("userInfo", JSON.stringify(res.result), {
              expires: 7
            });
          } else {
            Cookies.set("userInfo", JSON.stringify(res.result));
          }
          this.setStore("userInfo", res.result);
          this.$store.commit("setUserInfo", res.result);
          // 加载菜单
          util.initRouter(this);
          // window.location.reload();
          this.$router.push({
            name: "home_index"
          });
        } else {
          this.loading = false;
        }
      });
    },
    submitLogin() {
      if (this.form.username == '' || this.form.password == '') {
        this.$Notice.error({
          title: '错误提示',
          desc: "请输入账号或密码"
        });
        return;
      }
      if (this.activeName == 'systemLogin') {
        login(this.form).then(res => {
          if (res.success) {
            this.afterLogin(res);
          } else {
            this.loading = false;
          }
        });
      } else {
        companyLogin(this.form).then(res => {
          if (res.success) {
            let result = res.result;
            let account = result.account;
            let company = result.company;
            Cookies.set("companyUser", JSON.stringify(account), {
              expires: 365
            });
            Cookies.set("company", JSON.stringify(company), {
              expires: 365
            });
            router.push('/companyTelenet');
          } else {
            alert("账号密码错误!");
          }
        });
      }
    },
    handleDropDown(v) {
      if (v == "test") {
        this.test();
      } else if (v == "resetByMobile") {
        this.$router.push({
          name: "reset"
        });
      } else if (v == "resetByEmail") {
        this.$router.push({
          name: "reset",
          query: {
            type: "1"
          }
        });
      }
    },
    showNotice() {
      getNotice().then(res => {
        if (res.success) {
          if (!res.result) {
            return;
          }
          let data = res.result;
          if (data.open && (data.title || data.content) && data.position == "LOGIN") {
            this.$Notice.info({
              title: data.title,
              desc: data.content,
              duration: data.duration
            });
          }
        }
      });
    },
    test() {
      this.$Notice.info({
        title: "测试体验账号",
        desc: "账号：test或test2<br>密码：123456"
      });
    }
  },
  mounted() {
    //this.showNotice();
    //this.test()
    //this.relatedLogin();
    //1this.getCaptchaImg();
  }
};