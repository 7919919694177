var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('body', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "sec_login"
  }, [_c('div', {
    staticClass: "login_tag"
  }), _c('div', {
    staticClass: "login_con"
  }, [_c('div', {
    staticClass: "login_con_password"
  }, [_c('p', {
    staticClass: "login_ttl",
    staticStyle: {
      "font-size": "32px"
    }
  }, [_vm._v("地磅称重管理系统")]), _c('div', [_c('Tabs', {
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c('TabPane', {
    attrs: {
      "label": "系统登录",
      "name": "systemLogin"
    }
  }), _c('TabPane', {
    attrs: {
      "label": "供应商登录",
      "name": "gysLogin"
    }
  })], 1)], 1), _c('div', {
    staticClass: "form_group"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/icon1.png")
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.username,
      expression: "form.username"
    }],
    attrs: {
      "type": "text",
      "placeholder": "输入您的账号"
    },
    domProps: {
      "value": _vm.form.username
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "username", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form_group"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/login/icon2.png")
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.password,
      expression: "form.password"
    }],
    attrs: {
      "type": "password",
      "placeholder": "输入您的密码"
    },
    domProps: {
      "value": _vm.form.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "password", $event.target.value);
      }
    }
  })]), _c('p', {
    staticClass: "checkbox_btn"
  }, [_c('label', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.saveLogin,
      expression: "form.saveLogin"
    }],
    staticClass: "js-agreecheck-input",
    attrs: {
      "type": "checkbox",
      "name": "entry-check",
      "autocomplete": "off"
    },
    domProps: {
      "checked": Array.isArray(_vm.form.saveLogin) ? _vm._i(_vm.form.saveLogin, null) > -1 : _vm.form.saveLogin
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.form.saveLogin,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.form, "saveLogin", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.form, "saveLogin", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.form, "saveLogin", $$c);
        }
      }
    }
  }), _c('span'), _vm._v("记住密码")])]), _c('div', {
    staticClass: "login_btn",
    on: {
      "click": _vm.submitLogin
    }
  }, [_vm._v("登录")])])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };